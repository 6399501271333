import React from 'react';

import { Box, Heading } from '@chakra-ui/react';

export default function NavHeader() {
  return (
    <Box
      height="58px"
      bg="brand.primary"
      color="essentials.white"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Heading fontSize={24} fontWeight={700}>
        Edurupt
      </Heading>
    </Box>
  );
}
