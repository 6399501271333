import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Navigate, Route, Routes } from 'react-router-dom';

const DashboardRoutesList = ({ DashBoardRoutes, permissions }) => {
  return (
    <Routes>
      {DashBoardRoutes.map((route, idx) => {
        return (
          route.element && (
            <Fragment key={idx}>
              {route.canAcess ? (
                <>
                  {route.canAcess.includes(permissions.type) && (
                    <>
                      {route.childRoutes ? (
                        <Route
                          path={route.path}
                          name={route.name}
                          element={<route.element role={permissions.type} />}>
                          {route.childRoutes.map((childRoute) => (
                            <Fragment key={`key-${childRoute.path}`}>
                              {childRoute.canAcess ? (
                                <>
                                  {childRoute.canAcess.includes(permissions.type) && (
                                    <Route
                                      path={childRoute.path}
                                      name={childRoute.name}
                                      element={<childRoute.element role={permissions.type} />}
                                    />
                                  )}
                                </>
                              ) : (
                                <Route
                                  path={childRoute.path}
                                  name={childRoute.name}
                                  element={<childRoute.element />}
                                />
                              )}
                            </Fragment>
                          ))}
                        </Route>
                      ) : (
                        <Route
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          element={<route.element role={permissions.type} />}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <Fragment>
                  {route.childRoutes ? (
                    <Route path={route.path} name={route.name} element={<route.element />}>
                      {route.childRoutes.map((childRoute) => (
                        <Fragment key={`key-${childRoute.path}`}>
                          {childRoute.canAcess ? (
                            <>
                              {childRoute.canAcess.includes(permissions.type) && (
                                <Route
                                  path={childRoute.path}
                                  name={childRoute.name}
                                  element={<childRoute.element />}
                                />
                              )}
                            </>
                          ) : (
                            <Route
                              path={childRoute.path}
                              name={childRoute.name}
                              element={<childRoute.element />}
                            />
                          )}
                        </Fragment>
                      ))}
                    </Route>
                  ) : (
                    <Route
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      element={<route.element />}
                    />
                  )}
                </Fragment>
              )}
            </Fragment>
          )
        );
      })}

      <Route path="/" exact={true} element={<Navigate to="/dashboard" replace />} />
      <Route path="*" element={<Navigate to="404" replace />} />
    </Routes>
  );
};

export default DashboardRoutesList;

DashboardRoutesList.propTypes = {
  DashBoardRoutes: PropTypes.array,
  permissions: PropTypes.object
};
