import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'services/firebase';
import logout from 'actions/auth/logout';
import { signOutFromApp } from 'services/firebase';

function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token);
  const [loading, setLoading] = useState(true);

  let location = useLocation();

  const LogOut = () => {
    try {
      dispatch(
        logout({
          navigate
        })
      );
      signOutFromApp();
    } catch (error) {
      console.warn('Error occured in logout!');
    }
  };

  useEffect(() => {
    if (!token) {
      dispatch({ type: 'setNextPath', path: location });
    } else {
      const unsubscribe = onAuthStateChanged(
        auth,
        (user) => {
          try {
            if (!user) {
              throw new Error('User not authenticated!');
            }
            let refreshToken = user.getIdToken();
            if (!refreshToken) {
              throw new Error('No token found!');
            }

            localStorage.setItem('edu_wa_session_token', refreshToken);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            LogOut();
            console.error('Error setting user state:', error);
          }
        },
        (error) => {
          // Handle errors from onAuthStateChanged
          console.error('Auth state change error:', error);
          setLoading(false);
          LogOut();
        }
      );

      return () => unsubscribe();
    }
  }, []);

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (loading) return <PageLoader />;

  return children;
}

AuthProvider.propTypes = {
  children: PropTypes.any
};

export default AuthProvider;
