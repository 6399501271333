import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys
);

const brandCircular = definePartsStyle({
  control: defineStyle({
    // rounded: 'full',
    border: '1px solid',
    borderColor: 'essentials.black',
    p: '10px',
    _checked: {
      borderColor: 'brand.accent',
      background: 'essentials.white',
      _focus: {
        background: 'essentials.white'
      },
      _hover: {
        background: 'essentials.white'
      }
    }
  }),
  icon: defineStyle({
    background: 'essentials.white',
    color: 'brand.accent'
  })
});

const isAnswerCheckBox = definePartsStyle({
  control: defineStyle({
    border: '1px solid',
    borderColor: 'essentials.black',
    p: '10px',
    _checked: {
      _disabled: {
        borderColor: 'essentials.black',
        background: 'essentials.white'
      }
    },
    _disabled: {
      borderColor: 'essentials.black',
      background: 'essentials.white'
    }
  }),
  icon: defineStyle({
    background: 'essentials.white',
    color: 'brand.accent'
  })
});

const Checkbox = defineMultiStyleConfig({
  variants: { brandCircular, isAnswerCheckBox }
});

export default Checkbox;
