import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  radioAnatomy.keys
);

const brandCircular = definePartsStyle({
  control: {
    border: '1px solid',
    borderColor: 'essentials.black',
    _checked: {
      borderColor: 'brand.accent',
      background: 'essentials.white',
      color: 'brand.accent',
      _disabled: {
        borderColor: 'brand.accent',
        background: 'essentials.white',
        color: 'brand.accent'
      }
    },
    _disabled: {
      borderColor: 'essentials.black',
      background: 'essentials.white'
    }
  }
});

const radio = defineMultiStyleConfig({
  variants: { brandCircular }
});

export default radio;
